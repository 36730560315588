import styled from 'styled-components'

import { bodyRegularStyle, heading1Style } from '../../typography'

export const Outer = styled.div`
  padding: 200px 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`
export const Title = styled.div`
  ${heading1Style}
  text-align: center;
`

export const Subtitle = styled.div`
  ${bodyRegularStyle}
  text-align: center;
`
