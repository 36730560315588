import type { PageProps } from 'gatsby'
import React from 'react'

import CrossMark from '../../static/images/cross-mark.png'
import QuestionMark from '../../static/images/question-mark.png'
import PageNotFound from '../components/layout/PageNotFound'
import { CAREERS_PATH } from '../constants'

const NotFoundPage = ({ location }: PageProps) => {
  const { pathname } = location
  if (pathname.includes('/jobs/')) {
    return (
      <PageNotFound
        headTitle={'Oops! Job not found'}
        img={CrossMark}
        title={`This job listing is no longer available.`}
        subtitle={`The job listing you're searching for is no longer available, but don't worry! We have many more opportunities waiting for you. Explore our current openings to find a job that suits your skills and career goals.`}
        buttonText={'Back to Careers'}
        buttonLink={CAREERS_PATH}
      />
    )
  } else {
    return (
      <PageNotFound
        headTitle={'Oops! Page not found'}
        img={QuestionMark}
        title={`Oops! We can't find that page`}
        subtitle={`Sorry, the page you're looking for doesn't exist. It may have been moved or removed altogether. You might want to try the homepage to see if you can find what you're looking for from there.`}
        buttonText={'Back to Home'}
        buttonLink={'/'}
      />
    )
  }
}

export default NotFoundPage
