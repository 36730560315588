import { navigate } from 'gatsby'
import React from 'react'

import SmallButtonPrimary from '../../../system/buttons/SmallButtonPrimary'
import Page from '../Page'

import { Outer, Subtitle, Title } from './styled'

interface Props {
  headTitle: string
  img?: string
  title: string
  subtitle?: string
  buttonText: string
  buttonLink: string
}

const PageNotFound = ({
  headTitle,
  img,
  title,
  subtitle,
  buttonText,
  buttonLink,
}: Props) => (
  <Page
    head={{
      title: headTitle,
    }}
  >
    <Outer>
      {img && <img src={img} alt={img} />}
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <SmallButtonPrimary onClick={() => navigate(buttonLink)}>
        {buttonText}
      </SmallButtonPrimary>
    </Outer>
  </Page>
)

export default PageNotFound
